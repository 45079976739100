/**
 * Vue Mixins
 * Author: Sonakshi Pandey
 * ES6 Module
 */

export default {
  methods: {
    /**
     * @description filter Request Object
     * @param {Object} data - Object
     * @returns {Object} Object
     */
    filterRequestBody(data) {
      for (const [key, value] of Object.entries(data)) {
        if (value === "" || value === null) {
          delete data[key];
        }
      }
      return data;
    },

    /**
     * @description get all sorted data by Name (property)
     * @param {Array} data - Array of JSON
     * @returns {Array} Sorted Array of JSON
     */
    sortByName: (data) =>
      data.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }),

    /**
     * @description get name capital(property)
     * @param {Array} data - Array of JSON
     * @returns {Array} Sorted Array of JSON
     */
    capitalize(word) {
      return (
        word
          //.split("")
          .map((letter, index) =>
            index ? letter.toLowerCase() : letter.toUpperCase()
          )
          .join("")
      );
    },

    /**
     * @description check Permission
     * @param {Array} data - Array of JSON
     * @returns {Array} Sorted Array of JSON
     */
    checkPermission(permissions) {
      const b = this.$store.state.authStore.currentPermission.map((o) => o.key);
      for (const key of permissions) {
        if (b.includes(key)) {
          return true;
        }
      }
    },

    // Fuction for Subscription to test the limit
    CheckSubscription(smodel, currentCount) {
      let currentSubscription =  this.$store.state.authStore.currentSubscription.config

      // Find the configuration for the specified model in the subscription
      const modelConfig = currentSubscription.find(config => config.model === smodel);
      console.log(modelConfig, 'modelConfig');
      if (modelConfig) {
        return currentCount < modelConfig.limit;
      }

      // If the model is not found in the configuration, it may not be subject to limits
      return true;
    },

    checkIsVerifiedUser() {
      return (this.$store.state?.authStore?.currentUser.account_status?.toLowerCase() === 'verified');
    }
  },
};
