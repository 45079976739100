import { getQueryString } from "@/util/helper";
import axios from "@/util/axios";
import { apiPath } from "@/config/apiPath";

const state = {
  roles: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getAllRoles: (state) => state.roles,
};

const mutations = {
  setAllRoles: (state, roles) => (state.roles = roles),
  doNothing: (state) => state,
  addToRole: (state, role) => state.roles.unshift(role),
  updateRole: (state, updatedRole) => {
    state.roles.forEach((role) => {
      if (role._id == updatedRole._id) {
        // Update Data
        role.name = updatedRole.name;
        role.description = updatedRole.description;
        role.status = updatedRole.status;
        role.permissions = updatedRole.permissions
      }
    });
  },
  removeRole: (state, id) => {
    state.roles = state.roles.filter((role) => role.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
};

const actions = {
  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },

  /**
   * @description Get All Roles
   * @param {Number} data - Roles Object
   * @returns Promise
   */
  async fetchAllRoles({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";
        params = { page: page, per_page: itemsPerPage, sort_by: sort_by, sort_order: sort_order };
      }
      let url = getQueryString(params).substring(1);
      axios.get(apiPath.roles.getAllRoles+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllRoles", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Search Role
   * @param {Number} data - Roles Object
   * @returns Promise
   */
  async searchRole({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let name = options.filter;
        params = { name, all: true };
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage,
        };
        let name = options.filter
        params = { name, ...params };
      }
      let url = getQueryString(params).substring(1);
      axios.get(apiPath.roles.searchRole+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllRoles", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Create New Role
   * @param {Object} data - Role Object
   * @returns Promise
   */
  createRole({ commit, }, data) {
    return new Promise((resolve, reject) => {
      axios.post(apiPath.roles.createRole, data)
        .then(async (res) => {
          if (res.data.success) {
            await commit("addToRole", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Update Role Info
   * @param {Object} data - Update Role Object
   * @returns Promise
   */
  updateRoleByID({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.put(apiPath.roles.updateRole + data._id, data)
        .then(async (res) => {
          if (res.data.success) {
            await commit("updateRole", data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Remove Role
   * @param {Number} id - Role Id
   * @returns Promise
   */
  async removeRoleById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(apiPath.roles.removeRole + id, {})
        .then(async (res) => {
          if (res.data.success) {
            await commit("removeRole", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
