import { apiPath } from "@/config/apiPath";
import axios from "@/util/axios";


const state = {};

const getters = {};

const mutations = {
  doNothing: (state) => state,
};

const actions = {
  /**
   * @description get data from the AI
   * @param {Object} data - Query
   * @returns Promise
   */
  getDataFromBackendAI({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.agpAI, data)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
