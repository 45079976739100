import { getQueryString } from "@/util/helper";
import axios from "@/util/axios";
import { apiPath } from "@/config/apiPath";

const state = {
  offices: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getAllOffices: (state) => state.offices,
};

const mutations = {
  setAllOffices: (state, payload) => (state.offices = payload),
  doNothing: (state) => state,
  addToOffice: (state, payload) => state.offices.unshift(payload),
  updateOffice: (state, updatedData) => {
    state.offices.forEach((ofc) => {
      if (ofc.id == updatedData.id) {
        ofc.name = updatedData.name;
      }
    });
  },

  removeOffice: (state, id) => {
    state.offices = state.offices.filter((office) => office.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
};

const actions = {
  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },

  /**
   * @description Get All offices
   * @param {Number} data - offices Object
   * @returns Promise
   */
  async fetchAllOffices({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";
        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);
      axios
        .get(apiPath.offices.getAllOffices+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllOffices", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Search office
   * @param {Number} data - offices Object
   * @returns Promise
   */
  async searchOffice({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let name = options.filter;
        params = { name, all: true };
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage,
        };
        let name = options.filter;
        params = { name, ...params };
      }
      let url = getQueryString(params).substring(1);
      axios
        .get(apiPath.offices.searchOffices+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllOffices", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Create New office
   * @param {Object} data - office Object
   * @returns Promise
   */
  createOffice({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.offices.createOffice, data)
        .then(async (res) => {
          if (res.data.success) {
            await commit("addToOffice", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Update Office Info
   * @param {Object} data - Update Office Object
   * @returns Promise
   */
  updateOfficeByID({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.offices.updateOffice+`${data._id}`, data)
        .then(async (res) => {
          if (res.data.success) {
            commit("updateOffice", data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
 * @description Remove Office
 * @param {Number} id - Office Id
 * @returns Promise
 */
  async removeOfficeById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(apiPath.offices.removeOffice + id, {})
        .then(async (res) => {
          if (res.data.success) {
            await commit("removeOffice", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
