export const apiPath = {
  auth: {
    login: "/v1/auth/login",
    refreshToken: "/v1/auth/validateRefreshToken",
    logout: "/v1/auth/logout",
    getCurrentUser: "/v1/auth/current",
    signup: 'v1/auth/signup',
    checkIsDuplicateMobileOrEmail: "/v1/users/check/email-phone/exist",
    verifyOTP: '/v1/auth/verify-user/otp',
    resendOTP: '/v1/auth/resend-user/otp',
    checkIsDuplicateCompanyName: '/v1/auth/check/company-name/exist',
    signUpUser: '/v1/auth/signup',
    uploadImage: '/v1/user-files/upload-profileImage',
    reportIssue: '/v1/users/report-issue',
  },
  
  agpAI: "/v1/agpai",
  brands: {
    getAllBrands: "/v1/brands",
    getBrandByID: "/v1/brands/",
    searchBrands: "/v1/brands/search/",
    filterBrands: "/v1/brands/filter",
    downloadBrands: "/v1/brands/download-excel",
  },

  brand_requirements: {
    createBrandRequirement: "/v1/brand_requirements/bulk",
    getAllBrandRequirements: "/v1/brand_requirements",
    getBrandRequirementByID: "/v1/brand_requirements/",
    updateBrandRequirement: "/v1/brand_requirements/",
    removeBrandRequirement: "/v1/brand_requirements/",
    searchBrandRequirements: "/v1/brand_requirements/search/",
    filterBrandRequirements: "/v1/brand_requirements/filter/",
    checkPublicRequirementById: "/v1/brand_requirements/public/",
    getAllMatchingProperties: "/v1/brand_requirements/matching-properties/",
    bulkBrandTemplate: "/v1/users/get/brand-requirement/template/",
    bulkBrandUploadFile: "/v1/brand_requirements/insert/multiple-requirements-csv/",
  },

  brokers: {
    createBroker: "/v1/broker",
    getAllBrokers: "/v1/broker",
    getAllBrokerProperties: "/v1/properties/broker/",
    updateBroker: "/v1/broker/",
    removeBroker: "/v1/broker/",
    getBrokerByID: "/v1/broker/",
    searchBrokers: "/v1/broker/search",
    filterBrokers: "/v1/broker/filter",
    downloadBrokers: "/v1/broker/download-excel",
  },

  currentCompany: {
    get: "/v1/brands/current",
    update: "/v1/brands/current",
  },

  subscription: {
    current: "/v1/subscription_plans/brands/",
    getAll: "/v1/subscription_plans/brands",
  },

  industries: {
    createIndustry: "/v1/meta/industry",
    getAllIndustries: "/v1/meta/industry",
    updateIndustry: "/v1/meta/industry/",
    removeIndustry: "/v1/meta/industry",
    getIndustryByID: "/v1/meta/industry/",
    searchIndustries: "/v1/meta/industry/search",
    filterIndustries: "/v1/meta/industry/filter",
    downloadIndustries: "/v1/meta/industry/download-excel",
  },

  landlords: {
    createLandlord: "/v1/landlord",
    getAllLandlords: "/v1/landlord",
    getAllLandlordProperties: "/v1/properties/landlord/",
    updateLandlord: "/v1/landlord/",
    removeLandlord: "/v1/landlord/",
    getLandlordByID: "/v1/landlord/",
    searchLandlords: "/v1/landlord/search",
    filterLandlords: "/v1/landlord/filter",
  },

  offices: {
    createOffice: "/v1/offices",
    getAllOffices: "/v1/offices",
    getAllOfficeProperties: "/v1/properties/offices",
    updateOffice: "/v1/offices/",
    removeOffice: "/v1/offices/",
    getOfficeByID: "/v1/offices/",
    searchOffices: "/v1/offices/search",
    filterOffices: "/v1/offices/filter",
    downloadOffices: "/v1/offices/download-excel",
  },

  permissions: {
    createPermission: "/v1/permissions",
    getAllPermissions: "/v1/permissions",
    getAllPermissionProperties: "/v1/properties/permissions",
    updatePermission: "/v1/permissions/",
    removePermission: "/v1/permissions/",
    getPermissionByID: "/v1/permissions/",
    searchPermissions: "/v1/permissions/search",
    filterPermissions: "/v1/permissions/filter",
    downloadPermissions: "/v1/permissions/download-excel",
  },

  properties: {
    createProperty: "/v1/properties",
    getAllProperties: "/v1/properties",
    updateProperty: "/v1/properties/",
    removeProperty: "/v1/properties/",
    getPropertyByID: "/v1/properties/",
    searchProperties: "/v1/properties/search",
    filterProperties: "/v1/properties/filter",
    downloadProperties: "/v1/properties/download-excel",
    uploadFileByProperty: "/v1/user-files/upload-properties/",
    uploadVideoFileByProperty: "/v1/user-files/upload-properties-video/",
    uploadVideo: "/v1/user-files/upload-properties-video/",
  },

  proposalRequest: {
    createPropRequest: "/v1/temp_requested_matching_prop_info",
    getAllRequestedProperty: "/v1/matching_property_requests",
    getAllProposalRequestByBrandReq:
      "/v1/matching_property_requests/brand_req/",
    updateProposalRequest: "/v1/matching_property_requests/",
    removeProposalRequest: "/v1/matching_property_requests/",
    getProposalRequestByID: "/v1/matching_property_requests/",
    searchProposalRequest: "/v1/matching_property_requests/search",
    filterProposalRequest: "/v1/matching_property_requests/filter",
    downloadProposalRequest: "/v1/matching_property_requests/download-excel",
  },

  proposal: {
    createProposal: "/v1/property_proposals",
    getAllProposals: "/v1/property_proposals",
    updateProposal: "/v1/property_proposals/",
    removeProposal: "/v1/property_proposals/",
    getProposalByID: "/v1/property_proposals/",
    searchProposal: "/v1/property_proposals/search",
    filterProposal: "/v1/property_proposals/filter",
    downloadProposal: "/v1/property_proposals/download-excel",
    getRequestedProposalByReqId:
      "/v1/temp_requested_matching_prop_info/requirement/",
  },

  roles: {
    createRole: "/v1/roles",
    getAllRoles: "/v1/roles",
    updateRole: "/v1/roles/",
    removeRole: "/v1/roles/",
    getRoleByID: "/v1/roles/",
    searchRole: "/v1/roles/search",
    filterRole: "/v1/roles/filter",
    downloadRole: "/v1/roles/download-excel",
  },

  schedule: {
    createVisitScheduling: "/v1/visit_schedulings",
    getAllVisitSchedulings: "/v1/visit_schedulings",
    updateVisitScheduling: "/v1/visit_schedulings/",
    removeVisitScheduling: "/v1/visit_schedulings/",
    getVisitSchedulingByID: "/v1/visit_schedulings/",
    searchVisitScheduling: "/v1/visit_schedulings/search",
    filterVisitSchedulings: "/v1/visit_schedulings/filter",
    downloadVisitSchedulings: "/v1/visit_schedulings/download-excel",
  },

  summary: {
    getAllSummary: "/v1/summary/all",
  },

  tags: {
    createTag: "/v1/meta/tag",
    getAllTags: "/v1/meta/tag",
    getAllTagProperties: "/v1/properties/tag",
    updateTag: "/v1/meta/tag/",
    removeTag: "/v1/meta/tag/",
    getTagByID: "/v1/meta/tag/",
    searchTag: "/v1/meta/tag/search",
    filterTags: "/v1/meta/tag/filter",
    downloadTags: "/v1/meta/tag/download-excel",
  },

  users: {
    createUser: "/v1/users",
    getAllUsers: "/v1/users",
    getAllUserProperties: "/v1/properties/User",
    updateUser: "/v1/users/",
    removeUser: "/v1/users/",
    getUserByID: "/v1/users/",
    searchUser: "/v1/users/search",
    filterUsers: "/v1/users/filter",
    downloadUsers: "/v1/users/download-excel",
    disable: "/v1/users/toggle-account-status/",
    updateUserProfile: "/v1/users/edit-profile",
    updateUserPassword: "/v1/auth/admin/reset-password/",
    changePassword: "/v1/auth/update-password",
    uploadFile: "/v1/user-files/upload-users/",
    uploadImage: "/v1/user-files/upload-profileImage",
    checkIsDuplicateMobileOrEmail: "/v1/users/check/email-phone/exist",
    notifications: "/v1/notifications",
    readNotifications: "/v1/notifications/read/notification/",
    help: "/v1/users/help-center",
  },

  visitSchedule: {
    createTag: "/v1/meta/tag",
    getAllTags: "/v1/meta/tag",
    getAllTagProperties: "/v1/properties/tag",
    updateTag: "/v1/meta/tag/",
    removeTag: "/v1/meta/tag/",
    getTagByID: "/v1/meta/tag/",
    searchTag: "/v1/meta/tag/search",
    filterTags: "/v1/meta/tag/filter",
    downloadTags: "/v1/meta/tag/download-excel",
    categories: "/v1/meta/category",
  },

  externalProperty: {
    getAllExternalProperty: "/v1/external_properties",
    createExternalPublicRequirement: "/v1/external_properties/",
    removeExternalProperty: "/v1/external_properties/",
    getExternalPropertyByID: "/v1/external_properties/",
  },

  brokerProperties: {
    getAllBrokerProperty: "/v1/broker_requirement_properties",
    getAllBrokerPropertiesByRequirementId:
      "/v1/broker_requirement_properties/requirement/",
    updateStatus: "/v1/broker_requirement_properties/",
    getPropertyStatusOptions: "/v1/users/db/enums",
  },
};
