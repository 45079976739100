import { getQueryString } from "@/util/helper";
import axios from "@/util/axios";
import axiosInstance from "@/util/axios";
import { apiPath } from "@/config/apiPath";
import { app_types } from "@/util/constants/role";

const loadFilterValue = () => {
  const filterValue = JSON.parse(localStorage.getItem("filterValue"));
  return filterValue
    ? filterValue
    : {
      filter: false, city: "",
      state: "",
      country: "",
      pincode: "",
    };
};

const state = {
  filterValue: loadFilterValue(),
  users: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
  notifications: null
};

const getters = {
  getAllUsers: (state) => state.users,
  getFilterValue: (state) => {
    return state.filterValue;
  },
  getNotifications: (state) => {
    return state.notifications;
  }
};

const mutations = {
  setFilterValue: (state, payload) => {
    state.filterValue = payload;
    localStorage.setItem("filterValue", JSON.stringify(payload));
  },
  doNothing: (state) => state,
  setAllUsers: (state, payload) => (state.users = payload),
  addToUser: (state, payload) => state.users.unshift(payload),
  updateToUser: (state, updatedUser) => {
    state.users.forEach((user) => {
      if (user.id == updatedUser._id) {
        user.full_name = updatedUser.full_name;
        user.nick_name = updatedUser.nick_name;
        user.email = updatedUser.email;
        user.user_type = updatedUser.user_type;
        user.account_status = updatedUser.account_status;
        user.mobile = updatedUser.mobile;
        user.country = updatedUser.country;
        user.state = updatedUser.state;
        user.city = updatedUser.city;
        user.pincode = updatedUser.pincode;
        user.street = updatedUser.street;
        user.designation = updatedUser.designation;
        user.bio = updatedUser.bio;
        user.office_id = updatedUser.office_id;
        user.company_id = updatedUser.company_id;
        user.links = updatedUser.links;
        user.image = updatedUser.image;
      }
    });
  },
  removeUser: (state, id) => {
    state.users = state.users.filter((u) => u.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
  setNotification(state,value) {
    let data = {}
    if(value.currentPage !== 1){
      data = { ...JSON.parse(JSON.stringify(value)), data: [...state.notifications.data,  ...value.data] }
    } else {
      data = value
    }
    state.notifications = data;
  },
  setReadNotification(state,value) {
    if(state.notifications && state.notifications.data){
      state.notifications.data = state.notifications.data.map((item) => {
        if(item._id == value && !item.isRead){
          item.isRead = true;
          if(state.notifications.unReadCount > 0){
            state.notifications.unReadCount -= 1;
          }
        }
        return item
      })
    }
  }
};

const actions = {
  updateFilterValue: ({ commit }, filterValue) => {
    commit("setFilterValue", filterValue);
  },

  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },

  /**
   * @description Get All User
   * @param {Number} data - Users Object
   * @returns Promise
   */
  async fetchAllUsers({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.users.getAllUsers + `?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllUsers", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Update User data
   * @param {Object} data - Update  Data Object
   * @returns Promise
   */
  updateUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.users.updateUser + `${data.id}`, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToUser", res.data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
  * @description Update User Profile
  * @param {Object} data - Update Profile Data Object
  * @returns Promise
  */
  updateUserPofile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.users.updateUserProfile, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToUser", res.data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * @description Update User password
   * @param {Object} data - Update password Data Object
   * @returns Promise
   */
  updateUserPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.users.changePassword, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToUser", data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  /**
   * @description Search User
   * @param {Number} data - User Object
   * @returns Promise
   */

  searchUser({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let name = options.filter;
        params = { name, all: true };
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage,
        };
        let name = options.filter;
        params = { name, ...params };
      }

      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.users.searchUser + `?${url}`)
        .then((res) => {
          if (res.data.success) {
            commit("setAllUsers", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Remove User
   * @param {Object} data - Remove User
   * @returns Promise
   */
  removeUserById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPath.users.removeUser + `${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("removeUser", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Get User Info by ID
   * @param {Number} id - User ID
   * @returns Promise
   */
  fetchUserByID({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.users.getUserByID + `${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Create New User by SUPER ADMIN
   * @param {Object} data - User data Object
   * @returns Promise
   */
  createUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.users.createUser, data)
        .then((res) => {
          if (res.data.success) {
            commit("addToUser", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
  * @description Updload File by User
  * @param {Object} data - Update user files
  * @returns Promise
  */
  uploadFileByUser({ commit }, data) {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("dirType", data.type);
    formData.append("app_type", data.app_type);
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.users.uploadFile, formData)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
  * @description Updload File by User
  * @param {Object} data - Update user files
  * @returns Promise
  */
   uploadFileProfile({ commit }, data) {
    const formData = new FormData();
    formData.append("file", data.file);
    // formData.append("dirType", data.type);
    formData.append("app_type", data.app_type);
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.users.uploadImage, formData)
        .then((res) => {
          console.log('test123', res.data, res.data.status)
          if (res.data.status) {
            console.log('inside if')
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Filter User
   * @param {Number} id - pincode , state, city
   * @returns Promise
   */
  async filterUser({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = {
          ...options, country: state.filterValue.country,
          state: state.filterValue.state,
          city: state.filterValue.city,
          pincode: state.filterValue.pincode,
        };
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
          country: state.filterValue.country,
          state: state.filterValue.state,
          city: state.filterValue.city,
          pincode: state.filterValue.pincode,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.users.filterUsers + `?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllUsers", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  checkIsDuplicateMobileOrEmail(_, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(apiPath.users.checkIsDuplicateMobileOrEmail, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },

  /**
   * @description get notifications
   * @returns Promise
   */
 async getNotifications({ commit }, data = {}) {
  const { page = 1 } = data
 return new Promise((resolve, reject) => {
   axios
     .get(apiPath.users.notifications+`?page=${page}`)
     .then(async (res) => {
       await commit("setNotification", res.data);
       resolve(res);
     })
     .catch((error) => {
       reject(error);
     });
 });
},

  /**
   * @description read notifications
   * @returns Promise
   */
 async readNotifications({ commit }, id) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${apiPath.users.readNotifications}${id}`)
      .then(async (res) => {
        resolve(res);
        commit('setReadNotification', id)
      })
      .catch((error) => {
        reject(error);
      });
    });
  },

  /**
   * @description save help
   * @returns Promise
   */
 async saveHelp({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiPath.users.help}`,{...data, appType: app_types.Brand})
      .then(async (res) => {
        resolve(res);
        commit('nothing')
      })
      .catch((error) => {
        reject(error);
      });
    });
  }
  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
